@if (!process.processId) {
  <jhc-progress-indicator></jhc-progress-indicator>
} @else {
  <div class="holder">
    <div class="subject">
      {{ process.subject }}
    </div>
    <div class="sign-list">
      <div
        *ngFor="let signature of process.signatures">
        <div class="sign-box good">
          <div class="sign-box-item">
            <div class="sign-header">Signerat</div>
            <div>{{ signature.signee }}</div>
          </div>
          <div class="sign-icon">
            <mat-icon class="material-symbols-sharp">check</mat-icon>
          </div>
        </div>
      </div>
      <div
        *ngFor="let signee of remainToSign">
        <div class="sign-box wait">
          <div class="sign-box-item">
            <div class="sign-header">Väntar på signering från:</div>
            <div>{{ signee }}</div>
          </div>
          <div class="sign-icon">
            <mat-icon class="material-symbols-sharp">notification_important</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="message-out">
      <p>När avtalet är signerat av alla parter blir signeringsbeviset nedan tillgängligt för nedladdning.</p>
      <p>Alla parter erhåller efter signering en länk hit för att kunna ladda ner avtalet och signeringsbeviset.<br>
        Det är viktigt att du sparat både avtalet och signeringsbeviset eftersom de hör ihop.
      </p>
      <p>Du kan läsa mer om signeringsbevis <span class="link" (click)="openDialog()">här</span></p>
    </div>
    <div class="document-holder">
      <div *ngFor="let document of process.documents">
        <div class="document">
          <mat-icon class="material-symbols-sharp icon">draft</mat-icon>
          <div class="text">
            <div class="name">{{document.name}}</div>
          </div>
          <div class="jhc-filler"></div>
          <a [href]="document.viewUrl" color="primary" mat-flat-button target="_blank">
            <div>
              <mat-icon class="material-symbols-sharp remove">download</mat-icon>
            </div>
          </a>
        </div>
      </div>

      <div [ngClass]="{ disabled: !process.complete }" class="document">
        <mat-icon class="material-symbols-sharp icon">draft</mat-icon>
        <div class="text">
          <div class="name">Signeringsbevis</div>
        </div>
        <div class="jhc-filler"></div>
        <button
          (click)="fetchEvidence()"
          [disabled]="!process.complete"
          color="primary"
          mat-flat-button>
          <div>
            <mat-icon class="material-symbols-sharp remove">download</mat-icon>
          </div>
        </button>
      </div>
    </div>
  </div>
}
