<div class="holder">
  <mat-icon class="material-symbols-sharp">draft</mat-icon>
  <div class="text">
    <div class="name">
      <a [href]="document.viewUrl" target="_blank">{{document.name}}</a>
    </div>
  </div>
  <div class="jhc-filler"></div>
  <mat-icon (click)="remove.emit(document.id)" *ngIf="removable" class="material-symbols-sharp remove">close
  </mat-icon>
</div>
