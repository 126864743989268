import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core'
import {filter, first, Subject, switchMap} from 'rxjs'
import {FILE_READER} from '../../../application/file-reader.provider'
import {Uploader} from '../../../application/uploader'
import {Process} from '../../../model/process'
import {DocumentService, IDocumentListItem, IDocumentPackage, IFileUpload} from '../../../services/document.service'
import {ProcessService} from '../../../services/process.service'

@Component({
  selector: 'jhc-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends Uploader implements OnInit {
  @ViewChild('fileUpload') fileUploadName: ElementRef = new ElementRef<any>(undefined)
  @Output() fileUpload: EventEmitter<string> = new EventEmitter<string>()

  public documents$: Subject<IDocumentListItem[]> = new Subject<IDocumentListItem[]>()

  /**
   * This is public so that we can *ngIf on length
   */
  public documents: IDocumentListItem[] = []

  /**
   * This is muy importante!
   */
  private packageId: string | undefined

  constructor(
    private documentsService: DocumentService,
    private processService: ProcessService,
    @Inject(FILE_READER) fileReader: FileReader) {
    super(fileReader)
  }

  public ngOnInit(): void {
    this.processService.case$.pipe(
      first(),
      filter((process: Process) => !!process.packageId),
      switchMap((process: Process) => this.documentsService.getDocumentPackage(process.packageId))
    ).subscribe({
      next: (pkg: IDocumentPackage) => {
        this.packageId = pkg.packageId
        this.documents = pkg.documents
        this.documents$.next(this.documents)
        this.fileUpload.emit(this.packageId)
      }
    })

    /**
     * Listen to base class result and if file is received save
     * it to server. After upload fetch the image url
     */
    this.result$.pipe(
      switchMap((data: IFileUpload) => this.documentsService.uploadDocumentData(data, this.packageId)),
    ).subscribe({
      next: (res: IFileUpload) => {
        // Set the view URL that we created.


        // Update our list and send the new list to update the ui.
        const listItem: IDocumentListItem = {
          name: res.name,
          contentType: res.contentType,
          date: new Date().toISOString(),
          id: res.id as string,
          size: res.size as number,
          viewUrl: res.viewUrl
        }
        this.documents.push(listItem)
        this.documents$.next(this.documents)
        // We need to reuse the package id for additional uploads
        this.packageId = res.packageId

        // Notify others that we now have a file loaded
        this.fileUpload.emit(this.packageId)
        this.isLoading = false
      }
    })
  }

  public deleteFile(id: string): void {
    this.documentsService.deleteDocument(this.packageId as string, id).subscribe()
    this.documents = this.documents.filter((d: IDocumentListItem) => d.id !== id)
    this.documents$.next(this.documents)
    this.packageId = this.documents.length ? this.packageId : undefined
    this.fileUpload.emit(this.packageId) // Let others know if we no longer have a package
    this.fileUploadName.nativeElement.value = ''
  }

  public openFile(id: string): void {
    this.documentsService.getViewUrl(id).subscribe({
      next: (url: string) => window.open(url, '_blank')
    })
  }
}
