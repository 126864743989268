<div class="holder">
  @switch (state) {
    @case ('confirmed') {
      <h2>
        Din e-post adress är verifierad.<br>
        Samtliga e-post adresser som du angivit har erhållit instruktioner för signering.
      </h2>
      <button
        [routerLink]="['/', PATH_MAIN, PATH_SIGN, process.processId, process.senderHash]"
        color="primary" mat-flat-button>Till signeringen
      </button>
    }
    @case ('failed') {
      <h2>Tyvärr!</h2>
      <p>Vi kunde inte verifiera din e-post!</p>
      <button
        [routerLink]="['/', PATH_MAIN, PATH_CREATE, process.processId]"
        color="primary" mat-flat-button>Skicka ny kod
      </button>
    }
    @case ('waiting') {
      <jhc-progress-indicator text='Kontrollerar'></jhc-progress-indicator>
    }
  }
</div>
