import {Component, OnInit} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {ConditionsDialogComponent} from '../../0-app/conditions/conditions-dialog/conditions-dialog.component'
import {ProcessService} from '../../services/process.service'

@Component({
  selector: 'jhc-accept-conditions',
  templateUrl: './accept-conditions.component.html',
  styleUrls: ['./accept-conditions.component.scss']
})
export class AcceptConditionsComponent implements OnInit {
  public type: 'person' | 'business' | undefined

  constructor(
    public processService: ProcessService,
    private matDialog: MatDialog
  ) {
  }

  public ngOnInit(): void {
    this.processService.resetConditions()
  }

  public openDialog(event: Event): void {
    event.stopPropagation()
    this.matDialog.open(ConditionsDialogComponent, {
      data: {type: 'villkor'},
      panelClass: 'panel'
    })
  }
}
