<div *ngIf="text" class="text-holder">
  <h2>{{ text }}</h2>
</div>

<div class="loading-holder">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>