import {Component, Input} from '@angular/core'

@Component({
  selector: 'jhc-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss']
})
export class ProgressIndicatorComponent {
  @Input() text: string = ''
}
