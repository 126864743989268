/**
 * Main module paths
 */
export const PATH_MAIN: string = 'main'
export const PATH_CREATE: string = 'create'
export const PATH_VERIFY: string = 'verify'
export const PATH_CONFIRMATION: string = 'confirmation'
export const PATH_SIGN: string = 'sign'
export const PATH_RESULT: string = 'result'

/**
 * Extra paths
 */
export const PATH_CONDITIONS: string = 'conditions'

/**
 * Route param names used in the routing modules
 */
export const ROUTE_PARAM_PROCESS_ID: string = 'processId'
export const ROUTE_PARAM_EMAIL_HASH: string = 'emailHash'
export const ROUTE_PARAM_TYPE: string = 'type'
export const ROUTE_PARAM_EMAIL: string = 'email'
