<div class="form-holder">
  @for (control of formArray.controls; track $index) {
    <input #entry
           maxlength="1"
           autocomplete="off"
           [formControl]="control"
           (paste)="onPaste($event, $index)"
           (keydown)="onKeyPress($event,$index)">
  }
</div>
