import {AfterViewInit, Component, ElementRef, EventEmitter, Output, QueryList, ViewChildren} from '@angular/core'
import {FormArray, FormControl, Validators} from '@angular/forms'

@Component({
  selector: 'jhc-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss']
})
export class CodeInputComponent implements AfterViewInit {
  protected readonly pattern = /^[A-Z]$/i
  protected readonly codeLength: number = 6

  @Output() valid = new EventEmitter<boolean>()
  @Output() code = new EventEmitter<string>()

  @ViewChildren('entry') entries: QueryList<any> = new QueryList<ElementRef<HTMLInputElement>>()

  public formArray: FormArray<FormControl> = new FormArray<FormControl>([])

  constructor() {
    // Create as many form entries as codeLength's value
    while (this.formArray.length < this.codeLength) {
      this.formArray.push(new FormControl('',
        {nonNullable: true, validators: [Validators.required, Validators.pattern(this.pattern)]}
      ))
    }

    this.formArray.valueChanges.subscribe(() => {
      this.valid.emit(this.formArray.valid)
      if (this.formArray.valid) {
        this.code.emit(this.formArray.controls.map((control: FormControl) => control.value).join(''))
      }
    })

    this.formArray.controls.forEach((control, index: number) => {
      control.valueChanges.subscribe((val: string) => {
        if (val) {
          // Character has been added, move focus one step forward
          this.entries.get(index + 1)?.nativeElement.focus()
        } else {
          // Character has been remove, move focus one step back
          this.entries.get(index - 1)?.nativeElement.focus()
        }
      })
    })
  }

  ngAfterViewInit() {
    // Focus first entry when created
    this.entries.get(0).nativeElement.focus()
  }

  public onPaste(event: ClipboardEvent, index: number) {
    event.stopPropagation()
    event.preventDefault()

    const paste = event.clipboardData
    if (paste) {
      const pasteArray = paste.getData('text').split('')
      let f = 0
      for (let i = index; i < this.formArray.controls.length; i++) {
        // Do nothing if we have more controls than values to paste
        if (i >= pasteArray.length) {
          return
        }

        this.formArray.controls[i].setValue(pasteArray[f])
        f++
      }
    }
  }

  public onDeletePress(event: KeyboardEvent, index: number): void {
    // If the current target's value is null or empty jump to previous entry
    if (!(event.target as HTMLInputElement).value) {
      event.preventDefault()

      // If index is first entry, do nothing, there are no more entries.
      if (index > 0) {
        this.entries.get(index - 1).nativeElement.focus()
      }
    }
  }

  public onKeyPress(event: KeyboardEvent, index: number): boolean {
    // Do not process 'delete' events since they are being processed in 'onDeletePress'
    if (event.key === 'Backspace' || event.key === 'Delete') {
      this.onDeletePress(event, index)
      return true
    }

    // Allow arrow or tab events directly for accessibility proposes but do not process them like the rest
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Tab') {
      return true
    }

    // Only admit characters allowed by the pattern
    const isAdmitted = this.pattern.test(event.key)
    if (isAdmitted) {
      // If the input already has a value then this new value will be written in the next entry
      const value = (event.target as HTMLInputElement).value
      if (value) {
        // If it is the last entry do not jump to the next one, there will be no more entries
        if (index < this.codeLength - 1) {
          this.entries.get(index + 1).nativeElement.focus()
        }
      }
    }

    return isAdmitted
  }
}
