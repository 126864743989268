<div class="upload">
  <jhc-file-upload (fileUpload)="packageId = $event"></jhc-file-upload>
</div>

<div>
  <div class="form-holder">
    <form [formGroup]="form">
      <div class="input-holder">
        <label for="subject">Ämne:</label>
        <input
          formControlName="subject"
          id="subject"
          placeholder="Ex: Avtalet vi ska signera"
        >
      </div>

      <div class="input-holder wrap-placeholder">
        <label for="recipient">Mottagare:</label>
        <mat-chip-grid #chipGrid aria-label="Mottagare">
          <mat-chip
            (removed)="removeRecipient(recipient.email)"
            *ngFor="let recipient of recipients">
            {{ recipient.email }}
            <button matChipRemove>
              <mat-icon class="material-symbols-sharp remove">close</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-grid>
        <input
          (matChipInputTokenEnd)="addRecipient($event)"
          [matChipInputAddOnBlur]="true"
          [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          formControlName="recipient"
          id="recipient"
          placeholder="exempel@exempel.com"
        >
      </div>

      <div class="input-holder">
        <label for="sender">Min e-post adress:</label>
        <input
          formControlName="sender"
          id="sender"
          placeholder="exempel@exempel.com"
        >
      </div>
      <div *ngIf="form.controls.recipient.dirty && form.errors && form.errors['same']">
        <div class="error-holder">
          <img alt="error" ngSrc="/assets/triangle.svg" height="24" width="27">
          <div class="error-text">Dokumentet behöver signeras från olika mottagare.</div>
        </div>
      </div>

      <div class="text-area">
        <label for="message">Meddelande:</label>
        <textarea
          formControlName="message"
          id="message"
        ></textarea>
      </div>

      <div class="bank-id-options">
        <label for="useBankId">Verifieringsalternativ:</label>
        <mat-radio-group
          ngDefaultControl
          aria-label="Verifieringsalternativ"
          color="primary"
          formControlName="useBankId"
          id="useBankId">
          <mat-radio-button [value]="true">Bank ID</mat-radio-button>
          <mat-radio-button [value]="false">Email</mat-radio-button>
        </mat-radio-group>
      </div>
    </form>

    <div class="conditions">
      <jhc-accept-conditions></jhc-accept-conditions>
    </div>

    <div class="jhc-filler"></div>

    <button
      [disabled]="form.invalid || !packageId || (bootstrapService.conditionsAccepted$ | async) !== true || recipients.length === 0"
      (click)="proceed()"
      class="action-button"
      color="primary" mat-flat-button
    >Godkänn villkoren och skicka
    </button>
  </div>
</div>
