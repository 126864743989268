import {CommonModule, NgOptimizedImage} from '@angular/common'
import {NgModule} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {MatButtonModule} from '@angular/material/button'
import {MatChipsModule} from '@angular/material/chips'
import {MatIconModule} from '@angular/material/icon'
import {MatRadioModule} from '@angular/material/radio'
import {BankIdModule} from '@jhc/bankid'
import {JHCCommonModule} from '../common/common.module'
import {CreateComponent} from './0-create/create.component'
import {FileUploadComponent} from './0-create/file-upload/file-upload.component'
import {CodeInputComponent} from './1-verify/code-input/code-input.component'
import {VerifyComponent} from './1-verify/verify.component'
import {ConfirmationComponent} from './2-confirmation/confirmation.component'
import {SignComponent} from './3-sign/sign.component'
import {ResultComponent} from './4-result/result.component'
import {InputDirective} from './directives/input.directive'

import {MainRoutingModule} from './main-routing.module'
import {MainComponent} from './main.component'

@NgModule({
  declarations: [
    CodeInputComponent,
    ConfirmationComponent,
    CreateComponent,
    FileUploadComponent,
    InputDirective,
    MainComponent,
    ResultComponent,
    SignComponent,
    VerifyComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    MatButtonModule,
    ReactiveFormsModule,
    JHCCommonModule,
    MatIconModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatRadioModule,
    NgOptimizedImage,
    BankIdModule
  ],
  exports: [
    InputDirective
  ]
})
export class MainModule {
}
