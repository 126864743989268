import {COMMA, ENTER, SEMICOLON} from '@angular/cdk/keycodes'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {MatChipInputEvent} from '@angular/material/chips'
import {Router} from '@angular/router'
import {filter} from 'rxjs'
import {PATH_MAIN, PATH_VERIFY} from '../../application/data-types'
import {IProcess, IRecipient, Process} from '../../model/process'
import {BootstrapService} from '../../services/bootstrap.service'
import {ProcessService} from '../../services/process.service'
import {emailValidatorFactory} from '../directives/email.validator'

@Component({
  selector: 'jhc-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnDestroy {
  /**
   * The package id is needed to be able to proceed from this
   * component
   */
  public packageId: string = ''

  public recipients: IRecipient[] = []

  public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON]

  public form = new FormGroup({
    subject: new FormControl('', [Validators.required]),
    recipient: new FormControl('', [Validators.email]),
    sender: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', Validators.required),
    useBankId: new FormControl(true, Validators.required)
  }, emailValidatorFactory(() => this.recipients))

  constructor(
    public bootstrapService: BootstrapService,
    private processService: ProcessService,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.processService.case$.pipe(
      filter((process: Process) => !!process.recipients)
    ).subscribe({
      next: (process: Process) => {
        this.form.patchValue(process)
        this.recipients = process.recipients
      }
    })

    // Make sure that sender's email is trimmed
    this.form.controls.sender.valueChanges
      .pipe(filter(Boolean))
      .subscribe((value) => this.form.controls.sender.setValue(
        value.trim(), {emitEvent: false}))
  }

  public ngOnDestroy(): void {
    this.processService.case$.next(this.createCase())
  }

  public proceed(): void {
    const res = this.createCase()
    this.processService.case$.next(res)
    // Blindly create a case on server then navigate
    this.processService.createCase().subscribe({
      next: (process: IProcess) => {
        this.router.navigate(['/', PATH_MAIN, PATH_VERIFY, process.processId]).then()
      }
    })
  }

  public addRecipient(event: MatChipInputEvent): void {
    const value = event.value.trim()

    // Make sure that control's value is trimmed before it's validity check
    this.form.controls.recipient.setValue(value)

    if (this.form.controls.recipient.valid && event.value) {
      // Remove value from recipient's input once we know it's valid
      this.form.controls.recipient.setValue(null)

      this.recipients.push({email: value})
      event.chipInput.clear()
      this.form.updateValueAndValidity()
    }
  }

  public removeRecipient(recipient: string): void {
    const index = this.recipients.findIndex((r: IRecipient) => r.email === recipient)
    this.recipients.splice(index, 1)
    this.form.updateValueAndValidity()
  }

  private createCase(): Process {
    const signingCase: any = {
      sender: this.form.controls.sender.value as string,
      recipients: this.recipients,
      packageId: this.packageId,
      message: this.form.controls.message.value as string,
      subject: this.form.controls.subject.value as string,
      useBankId: this.form.controls.useBankId.value as boolean
    }
    return new Process(signingCase)
  }
}
