<div class="holder">
  @if (isLoading) {
    <jhc-progress-indicator text='Kontrollerar'></jhc-progress-indicator>
  } @else {
    <h2>Verifieringskod</h2>

    <jhc-code-input (valid)="isValidCode=$event" (code)="code=$event"></jhc-code-input>

    <div class="info-text">
      <div>Vi har skickat en verifieringskod till</div>
      <div><span class="email">{{ email }}</span></div>
      <div>för att kontrollera att du verkligen är du.</div>
      <div>Dyker mailet inte upp så kolla för säkerhets skull i din skräppost.</div>
    </div>

    <a (click)="send()">Fick du inget mail? Klicka här skickar vi ett nytt.</a>
    <div class="email-sent-feedback">{{ newEmailSent ? 'Ett nytt mail har skickats.' : '' }}</div>

    <button (click)="verify()" [disabled]="!isValidCode" color="primary" mat-flat-button>Verifiera</button>
  }
</div>
