import {CommonModule} from '@angular/common'
import {NgModule} from '@angular/core'
import {MatIconModule} from '@angular/material/icon'
import {MatRadioModule} from '@angular/material/radio'
import {RouterModule} from '@angular/router'
import {AcceptConditionsComponent} from './conditions/accept-conditions.component'
import {ProgressIndicatorComponent} from './progress-indicator/progress-indicator.component'
import {SafePipe} from './safe-pipe/safe.pipe'
import {SelectedComponent} from './selected/selected.component'


@NgModule({
  declarations: [
    AcceptConditionsComponent,
    SelectedComponent,
    ProgressIndicatorComponent,
    SafePipe,
  ],
  exports: [
    AcceptConditionsComponent,
    SelectedComponent,
    ProgressIndicatorComponent,
    SafePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatRadioModule,
    MatIconModule
  ]
})
export class JHCCommonModule {
}
