import {Component, OnInit} from '@angular/core'
import {first, map, of, switchMap} from 'rxjs'
import {PATH_MAIN, PATH_SIGN, PATH_VERIFY} from '../../application/data-types'
import {Process} from '../../model/process'
import {ProcessService} from '../../services/process.service'

@Component({
  selector: 'jhc-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  protected readonly PATH_CREATE = PATH_VERIFY
  protected readonly PATH_SIGN = PATH_SIGN
  protected readonly PATH_MAIN = PATH_MAIN

  public state: 'confirmed' | 'failed' | 'waiting' = 'waiting'

  public process: Process = new Process()

  constructor(
    private processService: ProcessService
  ) {
  }

  public ngOnInit(): void {
    this.processService.case$.pipe(
      first(),
      switchMap((process: Process) => {
        if (process.verifiedSender && !process.started) {
          return this.processService.startProcess().pipe(
            map((newProcess: Process) => {
                this.state = 'confirmed'
                this.processService.cancelCase(process.processId, newProcess.processId)
                return newProcess
              }
            ))
        }

        if (process.verifiedSender && process.started) {
          this.state = 'confirmed'
          return of(process)
        }
        this.state = 'failed'
        return of(process)
      })
    ).subscribe({
      next: (process: Process) => {
        this.process = process

        // If the process has been confirmed there is no need to save it again in storage
        if (this.state !== 'confirmed') {
          this.processService.case$.next(process)
        }
      }
    })
  }
}
