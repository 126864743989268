import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {ImageService} from '../../../services/image.service'

@Component({
  selector: 'jhc-conditions',
  templateUrl: './conditions-dialog.component.html',
  styleUrls: ['./conditions-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConditionsDialogComponent implements OnInit {

  public type: 'terms' | 'privacy' | 'faq' | 'integritet' | 'villkor' = 'privacy'

  public content = '<h2>Hämtar...</h2>'
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {type: string},
    public dialogRef: MatDialogRef<ConditionsDialogComponent>,
    private imageService: ImageService,
  ) {
  }

  public ngOnInit(): void {
    this.imageService.getText(this.data.type).subscribe({
      next: (html: string) => {
        setTimeout(() => this.content = html, 500)
      }
    })
  }
}
