<mat-radio-group
  (change)="processService.setConditions($event)"
  aria-label="Vem företräder du?"
  class="conditions"
  color="primary">
  <div class="radio-holder">
    <mat-radio-button [checked]="type === 'person'" value="terms">
      <span class="radio-label">Jag företräder mig själv - <span class="condition-link" (click)="openDialog($event)">Villkor</span></span>
    </mat-radio-button>
  </div>
  <div class="radio-holder">
    <mat-radio-button [checked]="type === 'business'" value="business">
      <span class="radio-label">Jag företräder ett företag - <span (click)="openDialog($event)" class="condition-link">Villkor</span></span>
    </mat-radio-button>
  </div>
</mat-radio-group>
