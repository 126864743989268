<div class="holder-c">
  <div (click)="dialogRef.close()" class="close">
    <mat-icon class="material-symbols-sharp">close</mat-icon>
  </div>
  <div class="document-c">
    <div class="inner-c">
      <jhc-selectable-text [text]="content"></jhc-selectable-text>
    </div>
  </div>
</div>
