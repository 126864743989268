import {FactoryProvider, InjectionToken} from '@angular/core'

export const LOCAL_STORAGE = new InjectionToken<Storage>('localStorage')
export const SESSION_STORAGE = new InjectionToken<Storage>('sessionStorage')

export const localStorageProvider: FactoryProvider = {
  provide: LOCAL_STORAGE,
  useFactory: () => localStorage
}

export const sessionStorageProvider: FactoryProvider = {
  provide: SESSION_STORAGE,
  useFactory: () => sessionStorage
}
