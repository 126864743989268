import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {map, Observable, switchMap} from 'rxjs'
import {environment} from '../../environments/environment'

export interface IFileUpload {

  /**
   * The name of the file
   */
  name: string

  /**
   * The content type, we will possibly use this
   * later.
   */
  contentType: string

  /**
   * Image data is a "Blob"?
   */
  documentData: Blob

  /**
   * The id as received from the server
   */
  id?: string

  /**
   * If several documents we collect them under one package
   */
  packageId?: string

  /**
   * If existing we can add the view URL here. The url must come
   * from the backends.
   */
  viewUrl: string

  /**
   * The size in bytes if available
   */
  size?: number
}

interface IPutRequestResult {
  /**
   * A timed url to use to upload an image to S3
   */
  putUrl: string

  /**
   * A timed url to view the document
   */
  viewUrl: string

  /**
   * The server side generated id <uuidv4>.<ext>
   */
  id: string

  /**
   * The server returns the new or existing package id.
   */
  packageId: string
}

export interface IDocumentListItem {
  name: string

  id: string

  size: number

  date: string

  contentType: string

  viewUrl: string
}

export interface IDocumentPackage {

  packageId: string

  documents: IDocumentListItem[]
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Upload a new document. It starts with creating an upload link, then uploads
   * the "data" part to S3.
   * @param document - The document as read from disk + some additional meta data.
   * @param packageId - If this is part of a package include it.
   */
  public uploadDocumentData(document: IFileUpload, packageId: string | undefined): Observable<IFileUpload> {
    const headers = {'Content-Type': document.contentType}
    let id = ''
    let newPackageId = ''
    let viewUrl = ''
    const data = {
      contentType: document.contentType,
      packageId,
      name: document.name,
      size: document.size
    }
    const url = `${environment.documentsUrl}/documents`
    return this.http.put<IPutRequestResult>(url, data).pipe(
      switchMap((r: IPutRequestResult) => {
        id = r.id
        newPackageId = r.packageId
        viewUrl = r.viewUrl
        const httpOptions = {
          headers: new HttpHeaders(headers)
        }
        return this.http.put<void>(r.putUrl, document.documentData, httpOptions)
      }),
      map(() => {
        document.id = id
        document.packageId = newPackageId
        document.viewUrl = viewUrl
        return document
      })
    )
  }

  public getViewUrl(id: string): Observable<string> {
    const url = `${environment.documentsUrl}/documents/${id}`
    return this.http.get(url).pipe(
      map((res: any) => res.signedUrl)
    )
  }

  public listDocuments(): Observable<IDocumentListItem[]> {
    const url = `${environment.documentsUrl}/documents`
    return this.http.get<IDocumentListItem[]>(url)
  }

  public deleteDocument(packageId: string, id: string): Observable<void> {
    const url = `${environment.documentsUrl}/packages/${packageId}/documents/${id}`
    return this.http.delete<void>(url)
  }

  public getDocumentPackage(packageId: string): Observable<IDocumentPackage> {
    const url = `${environment.documentsUrl}/packages/${packageId}`
    return this.http.get<IDocumentPackage>(url)
  }
}
