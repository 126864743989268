<input #fileUpload (change)="onFileSelected($event.target)"
       accept=".json, .pdf, .png, .jpg"
       class="file-input" type="file"/>

@if (documents.length === 0) {
  <div class="upload-holder-no-docs">
    <div *ngIf="!isLoading">
      <mat-icon (click)="fileUpload.click()" class="material-symbols-sharp">note_add</mat-icon>
    </div>

    <h2 (click)="fileUpload.click()">Lägg till dokument för signering</h2>
  </div>
} @else {
  <ng-container *ngIf="!isLoading">
    <div class="upload-holder">
      <mat-icon (click)="fileUpload.click()" class="material-symbols-sharp">note_add</mat-icon>
      <div (click)="fileUpload.click()">Lägg till fler dokument</div>
    </div>
  </ng-container>
}

<div *ngFor="let document of documents$ | async">
  <div class="uploaded-holder">
    <jhc-selected (remove)="deleteFile($event)" [document]="document"></jhc-selected>
  </div>
</div>

<div class="loading-holder" *ngIf="isLoading">
  <jhc-progress-indicator></jhc-progress-indicator>
</div>
