import {Component, HostListener, Input} from '@angular/core'

@Component({
  selector: 'jhc-selectable-text',
  templateUrl: './selectable-text.component.html'
})
export class SelectableTextComponent {
  @Input() text: string = ''

  private selectableTextIsHovered: boolean = false

  @HostListener('window:keydown.control.a', ['$event'])
  public selectAllWithControl(event: KeyboardEvent) {
    this.selectAll(event)
  }

  @HostListener('window:keydown.meta.a', ['$event'])
  public selectAllWithCmd(event: KeyboardEvent) {
    this.selectAll(event)
  }

  public onMouseEnterSelectableText() {
    this.selectableTextIsHovered = true
  }

  public onMouseLeaveSelectableText() {
    this.selectableTextIsHovered = false
  }

  private selectAll(event: KeyboardEvent) {
    if (this.selectableTextIsHovered) {
      event.preventDefault()

      const selection = document.getSelection()
      const node = document.getElementById('selectableText')
      if (node && selection) {
        const range = document.createRange()
        range.selectNodeContents(node)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    }
  }
}
