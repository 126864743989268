import {Component, Inject, OnInit} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {switchMap} from 'rxjs'
import {ConditionsDialogComponent} from '../../0-app/conditions/conditions-dialog/conditions-dialog.component'
import {ROUTE_PARAM_EMAIL, ROUTE_PARAM_PROCESS_ID} from '../../application/data-types'
import {WINDOW} from '../../application/window.provider'
import {IRecipient, ISignature, Process} from '../../model/process'
import {IEvidenceResult, ProcessService} from '../../services/process.service'

@Component({
  selector: 'jhc-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  public process: Process = new Process()

  public remainToSign: string[] = []

  private email: string | null = null

  constructor(
    private route: ActivatedRoute,
    private processService: ProcessService,
    @Inject(WINDOW) private injectedWindow: Window,
    private matDialog: MatDialog
  ) {
  }

  public ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const processId = params.get(ROUTE_PARAM_PROCESS_ID)
        this.email = params.get(ROUTE_PARAM_EMAIL)
        return this.processService.getProcess(processId as string, this.email as string)
      })
    ).subscribe({
      next: (process: Process) => {
        this.process = process
        const signers = process.recipients.map((recipient: IRecipient) => recipient.email)
        // Signers = rex, a,
        // Signatures [signee: rex, signee: a]
        this.remainToSign = signers.filter((s: string) => {
          return !process.signatures.find((signature: ISignature) => signature.signee === s)
        })
      }
    })
  }

  public fetchEvidence(): void {
    this.processService.getEvidence(this.process.processId, this.email as string)
      .subscribe({
        next: (res: IEvidenceResult) => {
          this.injectedWindow.open(res.signedUrl, '_blank')
        }
      })
  }

  public openDialog(): void {
    this.matDialog.open(ConditionsDialogComponent, {
      data: {type: 'faq'}
    })
  }
}
