import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {
  PATH_VERIFY,
  PATH_RESULT,
  PATH_SIGN,
  PATH_CREATE,
  PATH_CONFIRMATION,
  ROUTE_PARAM_EMAIL,
  ROUTE_PARAM_EMAIL_HASH,
  ROUTE_PARAM_PROCESS_ID
} from '../application/data-types'
import {CreateComponent} from './0-create/create.component'
import {VerifyComponent} from './1-verify/verify.component'
import {ConfirmationComponent} from './2-confirmation/confirmation.component'
import {SignComponent} from './3-sign/sign.component'
import {ResultComponent} from './4-result/result.component'
import {MainComponent} from './main.component'

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: PATH_CREATE,
        pathMatch: 'full'
      },
      {
        path: PATH_CREATE,
        component: CreateComponent
      },
      {
        path: `${PATH_VERIFY}/:${ROUTE_PARAM_PROCESS_ID}`,
        component: VerifyComponent
      },
      {
        path: `${PATH_CONFIRMATION}/:${ROUTE_PARAM_PROCESS_ID}/:${ROUTE_PARAM_EMAIL_HASH}`,
        component: ConfirmationComponent
      },
      {
        path: `${PATH_SIGN}/:${ROUTE_PARAM_PROCESS_ID}/:${ROUTE_PARAM_EMAIL}`,
        component: SignComponent
      },
      {
        path: `${PATH_SIGN}/:${ROUTE_PARAM_PROCESS_ID}/:${ROUTE_PARAM_EMAIL}/${PATH_RESULT}`,
        component: ResultComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
