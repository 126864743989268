import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {switchMap} from 'rxjs'
import {environment} from '../../../environments/environment'
import {
  PATH_MAIN,
  PATH_RESULT,
  PATH_SIGN,
  ROUTE_PARAM_EMAIL,
  ROUTE_PARAM_PROCESS_ID
} from '../../application/data-types'
import {Process} from '../../model/process'
import {BootstrapService, IConditions} from '../../services/bootstrap.service'
import {ProcessService} from '../../services/process.service'

export interface Signer {
  accessToken: string
  processId: string
  email: string
  signed: boolean
  conditions: IConditions
  useBankId: boolean
}

@Component({
  selector: 'jhc-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit {
  public bankIdUrl = environment.bankIdUrl
  public apiUrl = environment.processUrl
  public process: Process = {} as any

  public email: string = ''
  public pleaseWait = false

  private signResultPath: string[] = []

  constructor(
    public bootstrapService: BootstrapService,
    private processService: ProcessService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const processId = params.get(ROUTE_PARAM_PROCESS_ID) as string
        this.email = params.get(ROUTE_PARAM_EMAIL) as string
        // Set the (potential) sign result path
        this.signResultPath = ['/', PATH_MAIN, PATH_SIGN, processId, this.email, PATH_RESULT]
        return this.processService.getProcess(processId, this.email)
      })
    ).subscribe({
      next: (process: Process) => {
        this.process = process
        this.bootstrapService.conditionsAccepted$.next(process.acceptedByRequester)
        if (process.signedByRequester) {
          this.router.navigate(this.signResultPath).then()
        }
      }
    })
  }

  public sign(): void {
    this.processService.sign(this.process.processId, this.email, true).pipe().subscribe({
      next: () => this.router.navigate(this.signResultPath)
    })
  }

  public signCompleted(accessToken: string) {
    this.pleaseWait = true
    const signer: Signer = {
      accessToken: accessToken,
      processId: this.process.processId,
      email: this.email,
      signed: true,
      conditions: {} as any,
      useBankId: this.process.useBankId
    }
    this.processService.signWithBankIdCompleted(signer).subscribe({
      next: () => this.router.navigate(this.signResultPath)
    })
  }
}
