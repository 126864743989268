import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms'
import {IRecipient} from '../../model/process'

export const emailValidatorFactory = (getRecipients: () => IRecipient[]): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const recipients = getRecipients()
    const sender = control.get('sender')
    if (!sender || recipients.length === 0) {
      return null
    }
    const exist = recipients.find((recipient: IRecipient) => recipient.email === sender.value)
    return exist ? {same: true} : null
  }
}
