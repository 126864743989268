import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {first, forkJoin, of, switchMap} from 'rxjs'
import {PATH_CONFIRMATION, PATH_MAIN, ROUTE_PARAM_PROCESS_ID} from '../../application/data-types'
import {Process} from '../../model/process'
import {EmailService, IVerificationResponse, IVerifyCodeResponse} from '../../services/email.service'
import {ProcessService} from '../../services/process.service'

@Component({
  selector: 'jhc-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  public email: string = 'example@example.com'

  public isValidCode: boolean = false
  public code: string = ''

  public isLoading: boolean = false

  /**
   * The url is received from backend for verification, we append the code
   */
  private url: string = ''

  /**
   * Needed to be able to continue on the existing process.
   */
  private processId: string = ''

  /**
   *
   */
  private emailHash: string = ''

  /**
   * This is what we append to and go to when we have a successful verification
   */
  private verifyPath: string[] = ['/', PATH_MAIN, PATH_CONFIRMATION]

  /**
   * It tracks if a new email-code has been sent
   */
  public newEmailSent: boolean = false

  constructor(
    private processService: ProcessService,
    private emailService: EmailService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  public ngOnInit(): void {
    this.isLoading = true

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return this.processService.getCase(params.get(ROUTE_PARAM_PROCESS_ID) as string)
      }),
      switchMap((process: Process) => {
        this.email = process.sender
        this.processId = process.processId
        this.emailHash = process.senderHash as string
        // return forkJoin([of({hash: '', email: '', verified: false, url: ''}), of(process)])
        return forkJoin([this.emailService.createVerification(this.email, this.processId), of(process)])
      })
    )
      .subscribe({
        next: (res: [IVerificationResponse, Process]) => {
          this.url = res[0].url
          res[1].verifiedSender = res[0].verified
          if (res[1].verifiedSender) {
            this.verifyPath.push(...[res[1].processId, res[1].senderHash as string])
            this.router.navigate(this.verifyPath).then()
          }
          this.processService.case$.next(res[1])

          this.isLoading = false
        }
      })
  }

  public verify(): void {
    let verified = false
    this.emailService.verifyCode(`${this.url}${this.code}`)
      .pipe(
        switchMap((res: IVerifyCodeResponse) => {
          verified = res.verified
          return this.processService.case$
        }),
        first()
      )
      .subscribe({
        next: (process: Process) => {
          process.verifiedSender = verified
          this.processService.case$.next(process)
          this.verifyPath.push(...[process.processId, this.emailHash])
          this.router.navigate(this.verifyPath).then()
        }
      })
  }

  /**
   * Request a new link.
   */
  public send(): void {
    // Hide it for new iteration so that it looks that it's doing something
    this.newEmailSent = false

    this.emailService.createVerification(this.email, this.processId).subscribe({
      next: (res: IVerificationResponse) => {
        this.url = res.url

        // Show a feedback to the user for some time
        this.newEmailSent = true
      }
    })
  }
}
