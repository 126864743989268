import {ChangeDetectorRef, Directive, ElementRef, Input, OnChanges} from '@angular/core'

@Directive({
  selector: '[jhcInput]'
})
export class InputDirective implements OnChanges{

  @Input() public focusIndex: number = 0
  @Input() public index: number = 0
  constructor(private elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) { }

  public ngOnChanges(): void {
    if (this.index === this.focusIndex ) {
      this.elementRef.nativeElement.focus()
      this.changeDetectorRef.detectChanges()
    }
  }

}
