<!-- Add wait something something here -->
@if (!process.processId) {
  <jhc-progress-indicator></jhc-progress-indicator>
} @else {
  <div class="holder">
    <div class="intro">
      <h2>{{ process.sender }}</h2>
      <h2>har skickat följande till dig för signering</h2>
      @if (this.process.useBankId) {
        <p>Klicka på avtalet nedan och läs igenom det. Om du är nöjd signerar du därefter med ditt BankID.</p>
      } @else {
        <p>Klicka på avtalet nedan och läs igenom det. Om du är nöjd klickar du på knappen "Jag har granskat och skriver
          under" nedanför avtalet för att signera.</p>
      }
    </div>
    <div class="subject">
      {{ process.subject }}
    </div>
    @for (doc of process.documents; track process.processId) {
      <div class="document-list">
        <jhc-selected [document]="doc" [removable]="false"></jhc-selected>
      </div>
    }
    <jhc-accept-conditions
      *ngIf="!process.acceptedByRequester"
      class="conditions"></jhc-accept-conditions>

    @if (pleaseWait) {
      <p class="please-wait">Signering pågår, vänligen vänta...</p>
    }

    @if (!pleaseWait && this.process.useBankId && (bootstrapService.conditionsAccepted$ | async) === true) {
      <jhc-bankid
        (accessToken)="signCompleted($event)"
        [bankIdUrl]="bankIdUrl"
        [userRef]="this.process.processId"
        [apiUrl]="apiUrl"
        [style]="'margin-top:10px'"
      ></jhc-bankid>
    } @else if (!this.process.useBankId) {
      <div class="jhc-actions">
        <button
          (click)="sign()"
          [disabled]="(bootstrapService.conditionsAccepted$ | async) !== true"
          color="primary" mat-flat-button>
          <span class="button-text">Jag har granskat och skriver under</span>
        </button>
      </div>
    }
  </div>
}
